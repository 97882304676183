:root {
  --light-blue: #F2F3F7;
  --main-white: #fbfbfb;
  --main-blue: #103A8D;
  --main-black: #2E2E3A;
  --main-sky-blue: #359BEC;
  --main-sky-blue-hover: #2390e9;
  --menu-active-bg: #7EB1ED;
  --menu-bg: #F1F3F9;
  --main-light-blue: #FAFCFF;
  --white-border: 3px solid #FFFFFF;
  --bg-shadow-lg: -3px 6px 15px 3px #2c3d7928;
  --bg-shadow-sm: 0px 6px 8px 3px #2c3d7917;
  --bg-gradient: linear-gradient(#F9F8FD, #FFFFFF);
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

body {
  font-family: 'Lato' !important;
  font-smooth: always;
  font-smooth: 2em;
  font-size: 14px;
  font-weight: 400;
  background: url(../images/body-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: #f2f3f7;
}

.form-part {
  background-color: var(--main-light-blue);
  max-width: 675px;
  border-color: var(--main-light-blue);
  border: var(--white-border);
  margin: 100px auto 0px;
  box-shadow: var(--bg-shadow-lg);
  background-image: url(../images/form-bg.png);
  background-size: 350px;
  background-position: -30% 110%;
  background-repeat: no-repeat;
  border-radius: 8px;

}

.form-inner {
  max-width: 430px;
  margin: 50px auto 60px;
}

.google-login a {
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: var(--main-white);
  max-width: 220px;
  padding: 8px 15px;
  border-radius: 50px;
  box-shadow: var(--bg-shadow-sm);
  color: var(--main-blue);
  margin-top: 30px;
}

.google-login img {
  width: 40px;
}

.form-inner h2 {
  font-weight: bold;
  color: var(--main-blue);
}

.form-inner p {

  color: var(--main-blue);
}

.hr-line {
  margin-top: 50px;
  margin-bottom: 50px;
}

.forgot-pass,
.form-check-label {
  color: var(--main-blue);
  text-decoration: none;

}

.form-field .form-control {
  background: var(--main-white);
  border: none;
  padding: 12px 25px;
  box-shadow: var(--bg-shadow-sm);
  border-radius: 8px;
  border: var(--white-border);
  color: var(--main-blue);
}

.form-field .form-control::placeholder {
  color: var(--gray) !important;
  font-size: 14px;
}

.form-cta-btn a {
  background: var(--main-sky-blue);
  color: var(--main-white);
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 12px;
  border-radius: 50px;
  font-size: 18px;
}

#flipdRow .form-cta-btn a,
.dash-head .form-cta-btn a {
  font-size: 0.94rem !important;
}

.form-cta-btn a:hover {
  background: var(--main-sky-blue-hover);
  transition: all 0.22 ease-in-out;
}

.arrow-left {
  margin-left: -60px;
  margin-right: 8px;
}

.arrow-left:active {
  opacity: 0.8;
}

.forgot-pass-part {
  margin-top: 30vh;
}

.form-part.fp {
  max-width: 550px;
}

.form-inner.fp {
  max-width: 370px;
}

.list-group-item {
  background: transparent;
  border: none;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  justify-content: flex-start;
}

.list-group-item.active {
  background: transparent;
  border: none;
}

.list-group-item.active>div {
  background: var(--menu-active-bg);
  padding: 8px;
  border-radius: 12px;
}

.list-group-item div {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: visible;
  opacity: 1;
  transition: all 0.35s ease-in-out;
}

.list-group-item span {
  color: var(--main-blue);
  font-size: 18px;
  font-weight: 600;
  min-width: max-content;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in-out;
}

.menu-sub a {
  display: block;
  color: var(--main-blue);
  text-decoration: none;
  margin-bottom: 8px;
  font-weight: normal;

}

.menu-sub a:hover {
  color: var(--main-sky-blue-hover);
  transition: all 0.35s ease-in-out;
}

.menu-sub {
  padding-left: 60px;
}

.top-nav-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
  margin-bottom: 45px;
}

.top-nav #menu-toggle {
  display: none;
}

.profile img {
  width: 32px;
}

.profile a {
  display: flex;
  align-items: center;
  gap: 8px;
}

.profile span,
.profile a {
  color: var(--main-blue);
  text-decoration: none;
}

.profile span {
  font-weight: 700;
  padding-right: 15px;
}

.profile button {
  border: 0;
}

.dash-head {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 40px;
  justify-content: space-between;
}

.modal-body .dash-head {
  justify-content: left;
}

.dash-head h4,
.dash-head h3 {
  font-weight: 600;
  color: var(--main-sky-blue);
}

.dash-nav .nav-link.active {
  font-weight: 600 !important;
  transition: all 0.2s ease-in-out;
}

.dash-nav .nav-link {
  color: var(--main-blue) !important;
  background-color: transparent !important;
  position: relative !important;
  transition: all 0.2s ease-in-out;
  box-shadow: none !important;
  border-radius: 0 !important;
  text-transform: none !important;
  font-size: 1rem !important;
}

.dash-nav .nav-link.active::after {
  content: url(../images/active-nav-badge.png);
  position: absolute;
  bottom: -30px;
  left: calc(50% - 22px);
}

.dash-bg {
  background: var(--light-blue);
}

.form-field .form-select {
  appearance: auto;
  background: var(--main-white);
  border: none;
  padding: 12px 15px;
  box-shadow: var(--bg-shadow-sm);
  border-radius: 8px;
  height: 48px;
  border: var(--white-border);
}

.range-t {
  font-size: 15px;
  color: var(--main-blue);
  margin-top: 30px;
}

.range-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 23px;
}

.number-proxy {
  display: flex;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid lightgray;
}

.number-proxy div {
  color: var(--main-blue);
  font-size: 18px;
  font-weight: 500;
}

.search-box {
  min-width: 25%;
  background: white;
  display: flex;
  box-shadow: var(--bg-shadow-sm);
  border-radius: 3px;
  box-sizing: border-box;
}

.search {
  width: 100%;
}

.search {
  height: 3.2em;
  font-size: 0.8rem;
  border: 0;
  color: var(--main-blue);
  text-align: center;
}

.search-box img {
  margin-right: 15px;
}

.proxy-details-part {
  background-color: var(--main-white);
  border-radius: 16px;
  box-shadow: var(--bg-shadow-sm);
  max-height: 250px;
  overflow-y: scroll;
  border: var(--white-border);
}

textarea,
input {
  outline: none;
  color: var(--main-blue);
  padding: 4px 25px;
}

.proxy-details-part>div {
  color: var(--main-blue);
  padding: 4px 25px;
}

.proxy-details-part>div:hover {
  background: rgba(53, 155, 236, 0.1);
}

.proxy-details-part::-webkit-scrollbar {
  width: 10px;
}

.proxy-details-part::-webkit-scrollbar-track {
  background: transparent;
}

.proxy-details-part::-webkit-scrollbar-thumb {
  background: #E8F2FA;
  border-radius: 8px;
}

.proxy-details-part::-webkit-scrollbar-thumb:hover {
  background: #E8F2FA;
}

.cta-white {
  background-color: var(--main-white);
  color: var(--main-sky-blue);
  text-decoration: none;
  padding: 12px 25px;
  border-radius: 50px;
  box-shadow: var(--bg-shadow-sm);
  font-size: 18px;
}

.cta-white:hover {
  background-color: var(--main-light-blue);
  color: var(--main-sky-blue);
}

.plan-wrapper {
  background-image: var(--bg-gradient);
  box-shadow: var(--bg-shadow-sm);
  border-radius: 12px;
  padding: 5px 10px;
  border: var(--white-border);
  margin-bottom: 15px;
}

.plan-details h4 {
  font-weight: 600;
  color: var(--main-blue);
  margin-bottom: 15px;
}

.plan-details h5 {
  color: var(--main-blue);
}

.plan-wrapper {
  display: flex;
  align-items: center;
  gap: 25px;
  /* max-width: 550px; */
  position: relative;
}

.plan-bg {
  position: absolute;
  right: 10px;
  top: 10px;
}

.plan-svg {
  z-index: 99;
  position: relative;
}

.plan-details {
  position: relative;
  z-index: 99;
}

#page-content-wrapper.extra-p {
  padding: 20px 80px;
}

.sub-part {
  color: var(--main-blue);
}

.sub-part h5 {
  color: var(--main-blue);
  font-weight: 700;
}

.sub-part-nav .nav-link {
  background: var(--main-white);
  box-shadow: var(--bg-shadow-sm);
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  border-radius: 11px !important;
  padding: 15px 10px;
  min-width: 110px;
  max-width: 23%;
  width: 23%;
  text-align: center;
}

.sub-part-nav .nav-link {
  border: 2px solid transparent;
}

.sub-part-nav .nav-link.active {
  border: 2px solid var(--main-sky-blue);
}

.proxy-sub-details {
  display: flex;
  color: var(--main-blue);
  gap: 40px;
}

.swap-btn-outer {
  padding: 10px 15px;
  background-color: var(--main-white);
  height: max-content;
  box-shadow: var(--bg-shadow-sm);
  border: var(--white-border);
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.swap-btn-outer a {
  color: var(--main-blue);
  text-decoration: none;
}

.swap-btn-outer a:hover {
  color: var(--main-blue);
  text-decoration: none;
}

.swap-btn-outer:active {
  opacity: 0.8;
  transition: 0.1s al ease;
}

.proxy-sub-details h2 {
  font-weight: 600;
}

.form-field .col-form-label {
  padding-top: calc(.375rem + 5px);
  padding-bottom: calc(.375rem + 5px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  color: var(--main-blue);
}

.form-field .form-control::placeholder {
  color: var(--main-blue);
}

.change-pass a {
  color: var(--main-sky-blue);
}

.password-m .modal-body {
  background-color: var(--light-blue);
  padding: 25px 40px;
  position: relative;
  box-shadow: var(--bg-shadow-sm);
}

.password-m .modal-content,
.password-m .modal-body {
  border-radius: 12px;
  border: none;
}

.pop-bg-img {
  position: absolute;
  bottom: 0px;
  left: 0px;
  opacity: 0.5;
  /* z-index: 1; */
}

.up-z {
  z-index: 100;
  position: relative;
}

.modal-backdrop.show {
  /* opacity: 0.5; */
  /* filter: blur(8px); */
  opacity: 1;
  background-color: rgba(22, 22, 22, 0.055);
  backdrop-filter: blur(4px);
  transition: all 0.2s ease-in-out;
}

.modal-backdrop {
  /* opacity: 0.5; */
  /* filter: blur(8px); */
  opacity: 0;
  background-color: rgba(22, 22, 22, 0);
  backdrop-filter: blur(0px);
  transition: all 0.2s ease-in-out;
}

.change-p-out {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.profile-pic {
  background-color: var(--main-white);
  box-shadow: var(--bg-shadow-sm);
  border: var(--white-border);
  text-align: center;
  width: 100%;
}

.uploda-pic a,
.uploda-pic h6 {
  color: var(--main-blue);
}

.uploda-pic a:hover {
  color: var(--main-blue);
}

.text-blue {
  color: var(--main-blue) !important;
}

.pop-close-btn {
  position: absolute;
  right: 15px;
  right: 15px;
  opacity: 0.3;
  border: none;
  box-shadow: none !important;
}

.process-details {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 15px;
}

.w-custom {
  width: 40px !important;
  height: 20px;
  margin-right: 15px;
}

.w-custom-2 {
  width: 5px !important;
  height: 25px;
  margin-right: 15px;
}

.form-check-input:checked {
  background-color: var(--main-sky-blue);
  border-color: var(--main-sky-blue-hover);
}

.liner-g {
  background-image: linear-gradient(#FFFFFF, #F9F8FD);
  ;
}

.red-cta {
  background-color: #F5757D;
  transition: all 0.2s ease-in-out;
}

.red-cta:hover {
  background-color: #f5545f;
  transition: all 0.2s ease-in-out;
}

.payout-modal {
  padding: 30px 60px !important;
}

.dash-tabs .nav-link {
  background-color: var(--main-white);
  border: 0.64977px solid var(--main-blue);
  border-radius: 28px;
  min-width: 75px;
  color: var(--main-blue) !important;
  transition: all 0.2s ease-in-out;
}

.dash-tabs .nav-link.active {
  background-color: #E8F5F9;
  border: 0.64977px solid var(--main-sky-blue);
  border-radius: 28px;
  min-width: 75px;
  color: var(--main-sky-blue) !important;
}

.dash-tabs .nav-tabs {
  gap: 10px;
}

.dash-box {
  background-color: var(--main-white);
  border: var(--white-border);
  padding: 10px 15px 0 15px;
  box-shadow: var(--bg-shadow-sm);
  border-radius: 15px;
}

.purchase-storage .nav-link {
  background: url(../images/nav-bg.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  display: inline-block;
  min-width: 115px;
  min-height: 150px;
  border: var(--white-border);
  position: relative;
}

.purchase-storage .nav-link.active {
  background-image: linear-gradient(to top, #c0ecff5e, #ffffff62), url(../images/nav-bg.png);
  box-shadow: 0px 17px 14px rgba(10, 98, 230, 0.21);
  ;
}

.purchase-storage .nav-link.active::after {
  content: url(../images/green-icon.svg);
  position: absolute;
  top: 15px;
  right: 15px;
}

.proxy-g-bg {
  position: absolute;
  right: 10px;
  bottom: 0px;
  z-index: 1;
}

.gp-out {
  position: relative;
  z-index: 99;
}

.dash-box>.row {
  position: relative;
}

.plan-price-bg {
  background-image: url(../images/price-bg-part.png);
  background-size: contain;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.plan-nav .nav-link.active {
  background-color: var(--main-sky-blue);
  color: var(--main-white);
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
}

.plan-nav .nav-link {
  background-color: var(--main-white);
  color: var(--main-blue);
  border-radius: 50px;
  padding: 10px 25px;
  box-shadow: var(--bg-shadow-sm);
  transition: all 0.2s ease-in-out;
}

.plan-nav ul {
  gap: 15px;
  margin-top: 1rem;
}

.bullet li {
  list-style: none;
  margin-bottom: 8px;
  color: var(--main-blue);
}

.bullet li::before {
  content: url(../images/bullet-point.svg);
  padding-right: 8px;
}

.price-box-disc .form-select {
  height: 54px;
}

.plan-price-inner {
  background: var(--main-white);
  padding: 20px 30px 5px 30px;
  border: var(--white-border);
  box-shadow: var(--bg-shadow-sm);
  border-radius: 8px;
}

.small-navs .nav-link {
  padding: 4px 8px;
  font-size: 14px;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid var(--main-blue);
}

.small-navs .nav-link.active {
  border: 1px solid var(--main-sky-blue);
  border-radius: 4px;
}

.plan-blue {
  background: #2A3F89;
  color: var(--main-white) !important;
}

.plan-blue>* {
  color: var(--main-white) !important;
}

.plan-blue ul li {
  color: var(--main-white);
}

.plan-blue p {
  color: var(--main-white) !important;
}

.plan-blue ul li::before {
  color: var(--main-white);
  filter: brightness(15);
}

.dash-tabs .small-navs .nav-link {
  min-width: unset;
  border-radius: 8px;
}

.dash-tabs .small-navs .nav-link.active {
  background-color: var(--main-sky-blue);
  color: var(--main-white) !important;
}

.inner-isp-box {
  background: var(--main-white);
  border: var(--white-border);
  box-shadow: var(--bg-shadow-sm);
  padding: 15px 10px;
  border-radius: 8px;
}

.inner-isp-box.active {
  border-color: aquamarine;
}

.isp-img-top {
  position: absolute;
  right: 0px;
  top: 0px;
}

table th {
  color: var(--main-sky-blue);
}

table td {
  color: var(--main-blue);
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: #359aec18 !important;
}

.table-part .nav-pills {
  /* min-height: 103px; */
}

/* side nav */
#sidebar-wrapper {
  margin-left: -3px;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
}

.menu-sub {
  display: none;
}

.toggled .menu-sub {
  display: block;
}

.toggled #mainContainer,
.toggled main,
.toggled #page-content-wrapper {
  margin-right: -240px;
}

.toggled #sidebar-wrapper .list-group {
  width: 240px;
  transition: all 0.35s ease-in-out;
}

.toggled #sidebar-wrapper {
  margin-left: -3px;
  background-color: var(--menu-bg);
  transition: all 0.35s ease-in-out;
}

.toggled .list-group-item div {
  visibility: visible;
  opacity: 1;
}

.toggled .list-group-item span {
  visibility: visible;
  opacity: 1;
}

#page-content-wrapper .navbar-toggler svg {
  transform: scaleX(1);
  transition: all 0.35s ease-in-out;
}

.toggled #page-content-wrapper .navbar-toggler svg {
  transform: scaleX(-1);
  transition: all 0.35s ease-in-out;
}

.list-group-item.active img {
  filter: brightness(50);
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  text-decoration: none;
  background-color: transparent;
  border-radius: 12px;
}

.inner-day {
  border: 1px solid var(--main-sky-blue);
  padding: 20px;
  border-radius: 12px;
}

.blue .display-4 {
  color: var(--main-sky-blue) !important;
}

.yellow .display-4 {
  color: #F9D85E !important;
}

.red .display-4 {
  color: #F5757D !important;
}

.green .display-4 {
  color: #52D987 !important;
}

.blue .display-4 {
  color: var(--main-sky-blue) !important;
}

.yellow .display-4 {
  color: #F9D85E !important;
}

.red .display-4 {
  color: #F5757D !important;
}

.green .display-4 {
  color: #52D987 !important;
}

#pills-tabContent {
  padding-left: 0;
  padding-right: 0;
}

/* sidebar */
/* The side navigation menu */
#wrapper {
  overflow-x: hidden;
}

/* #sidebar-wrapper {
  background-color: var(--menu-bg);
  border: var(--white-border);
  border-radius: 0px 12px 12px 0px;
  min-height: 100vh;
  margin-left: -12.5rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  box-shadow: var(--bg-shadow-sm);
} */
#sidebar-wrapper {
  background-color: var(--main-blue);
  border: var(--white-border);
  border-radius: 0px 12px 12px 0px;
  min-height: 100vh;
  z-index: 1000;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  box-shadow: var(--bg-shadow-sm);
  transition: all 0.35s ease-in-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 85px;
  padding: 0rem 1.25rem;
  transition: all 0.35s ease-in-out;
}

#page-content-wrapper .container-fluid {
  padding: 1.875rem;
}

#page-content-wrapper {
  /* min-width: 100vw; */
  padding: 30px 20px;
}

#mainContainer,
#mainContainer main,
#page-content-wrapper {
  margin-right: 0;
  min-width: 0;
  width: 100%;
  transition: all 0.35s ease-in-out;
}

.dash-inner {
  margin-bottom: 20px;
  padding: 0 15px;
}

.menu-margin {
  margin-top: 2.5rem !important;
}

.dash-col {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
}

.balance-btn {
  margin-right: 0.9375rem;
  background: #ffffff;
  padding: 0.625rem 0.8125rem;
  border-radius: 0.625rem;
  margin-left: auto;
}

.balance-btn span {
  font-weight: 500;
  color: #7882a2;
}

.btn-icon {
  padding-right: 0.625rem;
}

.btn-icon svg {
  padding-right: 0.625rem;
}


/* #page-content-wrapper .border-bottom {
  border-bottom: 0.0625rem solid #404040 !important;
} */
/* @media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
} */

.active-menu {
  font-weight: 600;
}

/* end side nav */



/* gradient range css */

.range-slider {
  margin: 40px 0 0 0%;
}

.range-slider {
  width: 100%;
}

.range-slider__range {
  -webkit-appearance: none;
  width: calc(100% - (73px));
  height: 10px;
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 0;
}

.range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--main-sky-blue);
  border: var(--white-border);
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

.inner-day .range-slider__range::-webkit-slider-thumb {
  display: none;
}

.range-slider__range::-moz-range-thumb {
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 50%;
  background: var(--main-sky-blue);
  cursor: pointer;
  -moz-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

.range-slider__range:focus::-webkit-slider-thumb {
  box-shadow: 2px 4px 5px rgba(15, 14, 105, 0.15);
}

::-moz-range-track {
  background: #d7dcdf;
  border: 0;
}

@media (max-width:1366px) {
  #page-content-wrapper.extra-p {
    padding: 30px 20px;
  }

  /* html{
    font-size: 13px;
  } */
}

@media (max-width:1024px) {

  /* .dash-head {
    margin-bottom: 20px;
  } */
  .purchase-storage .nav-link {
    min-width: 100px;
  }

  #page-content-wrapper.extra-p {
    padding: 30px 10px;
  }

  .table-part .nav-pills {
    min-height: auto;
  }
}

@media (min-width: 1024px) {
  .saleDiv>div {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
}

@media (max-width:768px) {
  .form-part {
    padding-left: 15px;
    padding-right: 15px;
    margin: 30px auto 0px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .arrow-left {
    margin-left: unset;
    margin-right: 8px;
  }

  .form-field .form-group.row {
    --bs-gutter-y: 0rem;
  }

  .display-5 {
    font-size: 2.5rem;
  }

  #flipdRow .d-flex {
    flex-direction: column;
    width: 100%;
  }

  #flipdRow>.d-flex {
    gap: 30px !important;
  }

  #flipdRow .number-proxy,
  #flipdRow .form-cta-btn,
  #flipdRow .number-proxy {
    width: 100%;
  }
}

@media (max-width:670px) {
  .payout-modal {
    padding: 30px 25px 20px 25px !important;
  }

  .top-nav {
    box-shadow: 5px 14px 25px 0 #0d294214;
    padding: 12px 15px;
    border-radius: 0 0 13px 13px;
    background-color: white;
  }

  .top-nav #menu-toggle {
    display: inline-block;
  }

  #sidebar-wrapper {
    margin-left: -246px;
    /* visibility:hidden; */
    border: var(--white-border);
    background-color: var(--menu-bg);
    transition: all 0.35s ease-in-out;
  }

  #sidebar-wrapper .list-group {
    width: 240px;
    padding: 0rem 0.8rem;
    visibility: visible;
    opacity: 1;
    transition: all 0.35s ease-in-out;
  }

  #sidebar-wrapper .sidebar-heading {
    visibility: visible;
  }

  .toggled #sidebar-wrapper .sidebar-heading {
    visibility: visible;
  }

  .list-group-item div {
    /* opacity: 0; */
    visibility: visible;
  }

  .toggled #sidebar-wrapper {
    /* width: 240px; */
    margin-left: -3px;
    visibility: visible;
    border: var(--white-border);
  }

  .toggled #sidebar-wrapper .list-group {
    width: 240px;
    transition: all 0.35s ease-in-out;
  }

  #page-content-wrapper.extra-p {
    padding: 0;
  }

  .dash-head {
    align-items: flex-end;
    flex-direction: column;
  }

  .modal-body .dash-head {
    align-items: center;
    flex-direction: row;
  }

  .search-box {
    min-width: 100%
  }

  .profile span {
    padding-right: 0px;
  }

  .dash-head>div:first-child {
    width: 100%;
    justify-content: space-between;
  }

  .dash-head .right-switch {
    width: 100%;
    justify-content: right;
  }

  .left-switch {
    width: 100%;
    justify-content: left;
  }

  #flipdRow>.d-flex .d-flex:last-child {
    flex-direction: column-reverse !important;
  }
}

@media (max-width:580px) {
  .plan-wrapper {
    flex-wrap: wrap;
    gap: 9px;
    justify-content: center;
    text-align: center;
  }

  .cta-white {
    font-size: 14px;
  }

  #checkoutPayBtn {
    font-size: 18px;
  }

  .form-cta-btn a {
    font-size: 14px;
  }

  #flipdRow .form-cta-btn a {
    font-size: 1rem !important;
  }

  #sidebar-wrapper .list-group {
    width: 240px;
    padding: 0rem 0.8rem;
    transition: all 0.35s ease-in-out;
  }

  .dash-box {
    background-color: var(--main-white);
    border: var(--white-border);
    padding: 10px 10px 0 10px;
    box-shadow: var(--bg-shadow-sm);
    border-radius: 15px;
  }

  .dash-tabs .nav-link {
    min-width: auto !important;
  }

  .password-m .modal-content {
    margin-left: 15px;
    margin-right: 15px;
  }

  .saleDiv>div {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media (max-width:435px) {
  #monthly-select {
    width: 100%;
  }
}

@media (max-width: 400px) {
  #pills-tab-pricing li:first-child {
    width: 100%;
  }

  #pills-tab-pricing li:not(:first-child) {
    width: 47.5%;
  }

  .saleDiv>div {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width:349px) {
  #pills-tab-pricing li:not(:first-child) {
    width: 46%;
  }
}

/* range-dif */
.range-dif-part input {
  width: 100% !important;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
  .range-dif-part input[type='range'] {
    overflow: hidden;
    width: 80px;
    -webkit-appearance: none;
    background-color: #d6d6d6;
    border-radius: 16px;
  }

  .range-dif-part input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #359BEC;
    margin-top: -1px;
  }

  .yellow .range-dif-part input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #F9D85E !important;
    margin-top: -1px;
  }

  .red .range-dif-part input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #F5757D !important;
    margin-top: -1px;
  }

  .green .range-dif-part input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #52D987 !important;
    margin-top: -1px;
  }

  .range-dif-part input[type='range']::-webkit-slider-thumb {
    width: 00px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #434343;
    box-shadow: -80px 0 0 80px #359BEC;
  }

  .yellow .range-dif-part input[type='range']::-webkit-slider-thumb {
    width: 00px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #434343;
    box-shadow: -80px 0 0 80px #F9D85E;
  }

  .red .range-dif-part input[type='range']::-webkit-slider-thumb {
    width: 00px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #434343;
    box-shadow: -80px 0 0 80px #F5757D;
  }

  .green .range-dif-part input[type='range']::-webkit-slider-thumb {
    width: 00px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #434343;
    box-shadow: -80px 0 0 80px #52D987;
  }
}

/** FF*/
.range-dif-part input[type="range"]::-moz-range-progress {
  background-color: #43e5f7;
}

.range-dif-part input[type="range"]::-moz-range-track {
  background-color: #9a905d;
}

/* IE*/
.range-dif-part input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7;
}

.range-dif-part input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
}

/* scroll */
/* scroll bar */
/* width */
::-webkit-scrollbar {
  width: 0.3125rem;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-blue);
}

/* width */
.table-responsive::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
}

/* Track */
.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.table-responsive::-webkit-scrollbar-thumb {
  background: var(--main-blue);
  border-radius: 0.5rem;
}


.dash-form-part .title {
  font-size: 15px;
  color: var(--main-blue);
}

.swap {
  gap: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  color: var(--main-blue);
  cursor: pointer;
}

.swaping {
  gap: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  color: var(--main-blue);
  cursor: not-allowed;
}

.swap-disable {
  gap: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  color: var(--main-blue);
  cursor: not-allowed;
  opacity: 0.4;
}


/*  Admin design */

.card {
  color: var(--darker) !important;
  box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.1) !important;
}

.card .card-header-primary .card-icon,
.card .card-header-primary .card-text,
.card .card-header-primary:not(.card-header-icon):not(.card-header-text),
.card.bg-primary,
.card.card-rotate.bg-primary .front,
.card.card-rotate.bg-primary .back {
  background: var(--main-blue) !important;
  box-shadow: none !important;
}

.card .card-header-primary .card-icon img {
  height: 36px;
}

.login-page .container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.right-switch {
  width: 100%;
  justify-content: space-evenly;
}

/* Page Loader CSS */

.preloader {
  background: #19233e url("../images/preloader.svg") center no-repeat 80px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
}

.preloader-container {
  align-items: center;
  background: #1d2b52 url("../images/logo.png") no-repeat center/50px;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.preloader-loading-screen {
  height: 9.375rem;
  width: 9.375rem;
  border-radius: 50%;
  border: 1px solid var(--secondary);
  border-right: none;
  border-bottom: none;
  animation-name: spin, fade;
  animation-duration: 3s, 3s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1), linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1170deg);
  }
}

@keyframes fade {

  0%,
  100%,
  70% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}


.icon-btn {
  border: none !important;
  background-color: transparent !important;
  color: red !important;
}

.delete {
  color: red !important;
}

.add {
  color: green !important;
}

.blue {
  color: #103A8D !important;
}

/* Search Input Placeholder */

.search::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--main-blue);
  opacity: 1;
  /* Firefox */
  font-weight: 600;
}

.search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--main-blue);
}

.search::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--main-blue);
}


/*  React Datatable */
.rdt_TableHeadRow,
.rdt_Pagination {
  background-color: #fbfbfb !important;
}

.rdt_TableHead {
  color: var(--main-sky-blue) !important;
  font-size: medium !important;
  font-weight: 700 !important;
}

.rdt_TableHeadRow,
.rdt_TableRow,
.rdt_Pagination {
  border-bottom-width: 0px !important;
  border-top-width: 0px !important;
}

.rdt_TableRow,
.rdt_TableHeadRow {
  margin-bottom: 4px !important;
}

.rdt_TableRow {
  min-height: 36px !important;
  background-color: #F9F9FB !important;
  color: var(--main-blue) !important;
}

.rdt_TableCell {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.rdt_TableCell div:first-child,
.rdt_TableCol>div>div:first-child {
  overflow: visible !important;
  width: max-content !important;
  white-space: normal !important;
}

.rdt_Pagination {
  color: var(--main-blue) !important;
}

.kntihR,
.cCrguf,
.kGtBlX {
  background-color: #fbfbfb !important;
  color: var(--main-blue) !important;
}

.cCrguf div,
.kGtBlX div {
  font-size: 16px !important;
}

.number-proxy-box {
  width: 100%;
}

.number-proxy-box input {
  width: 100%;
}

.saleDiv {
  display: flex;
  flex-wrap: wrap;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

.saleDiv>div {
  flex: 0 0 35%;
  max-width: 35%;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
}

/* Sweetalert */

.swal2-actions button {
  border-radius: 50px !important;
  box-shadow: var(--bg-shadow-sm) !important;
}

.swal2-actions .swal2-confirm {
  background-color: var(--main-sky-blue) !important;
  color: var(--main-white) !important;
}

.swal2-actions .swal2-cancel {
  background-color: var(--main-white) !important;
  color: var(--main-blue) !important;
}

.swal2-input {
  width: 80% !important;
  border: var(--main-white) !important;
  box-shadow: var(--bg-shadow-sm) !important;
  color: var(--main-blue) !important;
}

.swal2-title {
  color: var(--main-sky-blue) !important;
  padding-top: 0.8em !important;
}

/* DateTime Picker */
.rdtPicker {
  top: 42px;
  left: 0px;
  width: inherit;
}

.rdtPicker td {
  height: 32px !important;
  border-radius: 5px;
}

/* Select2 */
.select div[class$="-control"] {
  /* position: absolute !important;
  border: 0px !important;
  top: -4px;
  right: 0px; */
  background: var(--main-white) !important;
  border: none !important;
  box-shadow: var(--bg-shadow-sm) !important;
  border-radius: 8px !important;
  border: var(--white-border) !important;
  color: var(--main-blue) !important;
}

.css-1s2u09g-control {
  min-height: 30px !important;
}

/* .css-1pahdxg-control:hover {
  border-color: transparent !important;
  border-width: 8px !important;
} */
.select div[class$="-indicatorSeparator"] {
  display: none !important;
}

.select div[class$="-menu"] {
  top: 100% !important;
  bottom: 100% !important;
  background-color: var(--main-white) !important;
}

.datacenter .select div[class$="-menu"],
.isp .select div[class$="-menu"] {
  top: 100% !important;
  bottom: inherit;
}

.select div[class$="-MenuList"] {
  background-color: var(--main-white) !important;
  border-radius: 10px;
  border: 2px solid lightgray;
}

.select div[class$="-placeholder"] {
  margin-left: 15px !important;
}

.select div[class$="-indicatorContainer"] {
  padding: 0 8px !important;
}

/* .select > div {
  left: 0px;
  position: absolute !important;
  border: 0px !important;
  top: -4px;
  right: 0px;
  background-color: var(--main-white) !important;
} */
.select div[class$="-singleValue"] {
  color: var(--main-blue) !important;
  margin-left: 15px !important;
}


/* Chrome, Safari, Edge, Opera */
.pool-order-div input::-webkit-outer-spin-button,
.pool-order-div input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.pool-order-div input[type=number] {
  -moz-appearance: textfield;
}

.circle-date {
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
}